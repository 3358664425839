body, html {
    overflow-X: hidden;
    height: 100%;
  }

.ant-checkbox-inner {
    border-color: #c7bcb8 !important;
}

/* .ant-menu.ant-menu-dark .ant-menu-item-selected {
    color: orange !important
} */

.ant-menu-item-selected {
    color: orange !important;
}